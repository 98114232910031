// eslint-disable-next-line import/no-cycle
import { TOKEN_PREFIX } from 'src/server/api/http';

import { useDecryptData, useEncryptData } from './useDecryptEncrypt';

export const useSetToken = (token) => {
  const encryptedToken = useEncryptData(token?.split(`_${TOKEN_PREFIX}_`)[0]);
  sessionStorage.setItem(TOKEN_PREFIX, encryptedToken);
};

export const useGetToken = (key) => {
  const encryptedToken = sessionStorage.getItem(key);
  const decryptData = useDecryptData(encryptedToken);
  return decryptData;
};

export const useSetRole = (role, name) => {
  const currentRole = role;

  const encryptedRole = useEncryptData(currentRole);

  if (currentRole) {
    sessionStorage.setItem('currentRole', encryptedRole);
    sessionStorage.setItem('user', name);
  }
};
// export const useSetUser = (value) => {
//   sessionStorage.setItem('user',value);
// };
// export const useGetUser = () => {
//   const user = sessionStorage.getItem('user');
//   return user;
// };
export const useSetUser = (value) => {
  sessionStorage.setItem('user',value);
};

export const useGetUser = () => {
  const user = sessionStorage.getItem('user');
  return user 
};

export const useSetEncryptLocalData = (key, value) => {
  const encrypted = useEncryptData(value);
  sessionStorage.setItem(key, encrypted);
};

export const useGetEncryptLocalData = (key) => {
  const encryptedData = sessionStorage.getItem(key);
  const decryptData = useDecryptData(encryptedData);
  return {
    data: decryptData?.token,
    isSuccess: decryptData?.isSuccess,
  };
};

export const useGetRoles = () => {
  const encryptedRole = sessionStorage.getItem('currentRole');
  const { isSuccess: successRole, token: role } = useDecryptData(encryptedRole);

  return {
    successRole,
    role,
  };
};

export const useClearStorage = () => {
  sessionStorage.clear();
};
