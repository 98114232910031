import * as React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

// components
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
// import { createEnquiry, updateEnquiryDetails } from 'src/server/api/enquiry';
// @mui
import {
  Stack,
  Dialog,
  Select,
  MenuItem,
  TextField,
  IconButton,
  InputLabel,
  DialogTitle,
  FormControl,
  DialogContent,
} from '@mui/material';

import { useResponsive } from 'src/hooks/use-responsive';

import { updateEnquiryStatus } from 'src/server/api/referrals';

// import CrudModalContent from './CrudModalContent';

EnquiryModalDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  isUpdate: PropTypes.object,
  setIsUpdate: PropTypes.any,
  setTab: PropTypes.any,
};

export default function EnquiryModalDialog({ open, setOpen, isUpdate, setIsUpdate, setTab }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  // console.log(isUpdate);
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useResponsive('down', 'md');
  const [statusValue, setStatusValue] = React.useState('');

  // const { role } = useGetRoles();
  // const { loading } = useSelector((state) => ({
  //   settingsDetails: state.settings.settingsDetails,
  //   loading: state.settings.loading,
  // }));
  // console.log(loading);

  const handleClose = () => {
    setOpen(false);
  };
  const statusOptions = [
    { label: 'New', value: 'NEW' },
    { label: 'Opened', value: 'OPENED' },
    { label: 'Closed', value: 'CLOSED' },
    { label: 'Confirmed', value: 'CONFIRMED' },
    { label: 'Pending', value: 'PENDING' },
  ];
  const handleTypeChange = (event, id) => {
    const newStatus = event.target.value;
    setStatusValue(newStatus);
    // console.log(newStatus);
    // console.log('id =>', id);
    const updateCredentials = {
      state: {
        status: newStatus,
        id: isUpdate?._id,
      },
      dispatch,
    };

    dispatch(updateEnquiryStatus(updateCredentials));
  };
  React.useEffect(() => {
    if (isUpdate?.status) {
      setStatusValue(isUpdate?.status);
    }
  }, [isUpdate?.status, setStatusValue]);

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: 'absolute', top: 0, right: 0, m: 1 }}
        >
          <CloseIcon sx={{ fontSize: '20px' }} />
        </IconButton>
        <DialogTitle id="responsive-dialog-title">
          {/* {isUpdate?._id ? 'Update your payment' : 'Complete your payment'} */}
          Enquiry Details
        </DialogTitle>
        <DialogContent
          className="crud-modal"
          sx={{ minWidth: isMobile ? '200px' : '450px', padding: '0px' }}
        >
          {/* <Stack p={3} pt={0}>
            <Stack textAlign="start">
              <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
                <AccountCircleIcon sx={{ fontSize: '20px' }} />
                <Typography variant="subtitle1">{isUpdate?.first_name}</Typography>
              </Stack>
              <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
                <EmailIcon sx={{ fontSize: '20px' }} />
                <Typography variant="subtitle1">{isUpdate?.email}</Typography>
              </Stack>
            </Stack>
            <Stack alignItems="flex-end">
              <Stack className="all-list-select-ui" my={2} width="150px">
                <FormControl width="150px" name="status" size="small">
                  <Select
                    value={statusValue}
                    onChange={handleTypeChange}
                    sx={{
                      backgroundColor: statusOptions.find((option) => option.value === statusValue)
                        ?.color,
                      color: 'black',
                    }}
                  >
                    {statusOptions.map((option) => (
                      <MenuItem key={option.value} value={option?.value} sx={{ color: 'black' }}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            </Stack>
            <Typography variant="subtitle1" mb={1}>
              Subject
            </Typography>
            <Typography
              sx={{
                wordWrap: 'break-word',
                overflowWrap: 'break-word',
                textAlign: 'start',
              }}
              variant="caption"
            >
              {isUpdate?.subject}
            </Typography>
            <Typography variant="subtitle1" mt={2} mb={1}>
              Message
            </Typography>
            <Typography
              sx={{
                wordWrap: 'break-word',
                overflowWrap: 'break-word',
                textAlign: 'start',
              }}
              variant="caption"
            >
              {isUpdate?.message}
            </Typography>
          </Stack> */}
          <Stack p={3} flexDirection="column">
            <Stack flex={7} flexDirection="row" gap={3}>
              <TextField
                name="Name"
                size="small"
                label="First Name"
                value={isUpdate?.first_name}
                type="text"
                InputLabelProps={{ shrink: true }}
                placeholder="Name"
                disabled
              />
              <TextField
                name="Name"
                size="small"
                label="Last Name"
                value={isUpdate?.last_name}
                type="text"
                InputLabelProps={{ shrink: true }}
                placeholder="Name"
                disabled
              />
            </Stack>
            <Stack flex={7} flexDirection="row" gap={3} mt={3}>
              <Stack width="100%">
                <TextField
                  name="email"
                  size="small"
                  label="Email"
                  value={isUpdate?.email}
                  type="text"
                  InputLabelProps={{ shrink: true }}
                  placeholder="Email"
                  disabled
                />
              </Stack>

              <Stack width="100%">
                <Stack className="all-list-select-ui">
                  <FormControl width="150px" name="status" size="small">
                    <InputLabel
                      sx={{
                        backgroundColor: 'white',
                        textAlign: 'center',
                        alignItems: 'center',
                        marginBottom: '5px',
                      }}
                    >
                      Status
                    </InputLabel>
                    <Select
                      value={statusValue}
                      onChange={handleTypeChange}
                      sx={{
                        backgroundColor: statusOptions.find(
                          (option) => option.value === statusValue
                        )?.color,
                        color: 'black',
                      }}
                    >
                      {statusOptions.map((option) => (
                        <MenuItem key={option.value} value={option?.value} sx={{ color: 'black' }}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
              </Stack>
            </Stack>
            <Stack flex={7} flexDirection="row" gap={3} mt={3}>
              <TextField
                sx={{ width: '100%' }}
                name="Subject"
                size="small"
                label="Subject"
                value={isUpdate?.subject}
                type="text"
                rows={2}
                multiline
                InputLabelProps={{ shrink: true }}
                placeholder="Subject"
                disabled
              />
            </Stack>
            <Stack flex={7} flexDirection="row" gap={3} mt={3}>
              <TextField
                sx={{ width: '100%' }}
                name="Message"
                size="small"
                label="Message"
                value={isUpdate?.message}
                type="text"
                rows={4}
                multiline
                InputLabelProps={{ shrink: true }}
                placeholder="Message"
                disabled
              />
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
}
