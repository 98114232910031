import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Stack from '@mui/material/Stack';
import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, IconButton, Typography, InputAdornment } from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';

import { getMemberProfile, updateMemberProfile } from 'src/server/api/auth';

import Iconify from 'src/components/iconify/iconify';
import { RHFTextField } from 'src/components/hook-form';
import { useSettingsContext } from 'src/components/settings';
import FormProvider from 'src/components/hook-form/form-provider';

// ----------------------------------------------------------------------

// const TABS = [
//   {
//     value: 'general',
//     label: 'General',
//     icon: <Iconify icon="solar:user-id-bold" width={24} />,
//   },

//   {
//     value: 'security',import * as Yup from 'yup';

//     label: 'Security',
//     icon: <Iconify icon="ic:round-vpn-key" width={24} />,
//   },
// ];

// ----------------------------------------------------------------------

export default function AccountView() {
  // const location = useLocation();
  const settings = useSettingsContext();
  const password = useBoolean();
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useResponsive('down', 'md');

  const dispatch = useDispatch();
  const { adminDetails } = useSelector((state) => ({
    loading: state.member.loading,
    adminDetails: state.auth.admin,
  }));
  // console.log(adminDetails);

  // const userUpdateSchema = Yup.object().shape({
  //   password: Yup.string().required('Password is required'),
  //   name: Yup.string(),
  //   email: Yup.string().required('email is required'),
  //   phone: Yup.string()
  //     .min(10, 'Phone must be at least 10 characters')
  //     .max(10, 'Phone must not exceed 10 characters'),
  // });

  const defaultValues = useMemo(
    () => ({
      name: adminDetails?.name || '',
      email: adminDetails?.email || '',
      phone: adminDetails?.phone || '',
      old_password: adminDetails?.password || '',
      new_password: '',
    }),
    [adminDetails]
  );
  const methods = useForm({
    // resolver: yupResolver(userUpdateSchema),
    defaultValues,
  });
  const {
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = methods;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const onSubmit = handleSubmit(async (data) => {
    // console.log(data);
    const credentialsCreate = {
      state: {
        ...data,
      },
      dispatch,
      token: adminDetails?.token,
      // handleClose,
      // handleClear,
    };
    // if () {
    //   const credentialsUpdate = {
    //     state: {
    //       ...data,
    //       is_active: isActive,
    //       is_verified: isVerified,
    //       is_paid: isPaid,
    //     },
    //     dispatch,

    //     navigate,
    //   };
    // dispatch(updateMemberDetails(credentialsUpdate));
    dispatch(updateMemberProfile(credentialsCreate));
  });

  useEffect(() => {
    const credentials = {
      dispatch,
      enqueueSnackbar,
    };
    dispatch(getMemberProfile(credentials));
  }, [dispatch, enqueueSnackbar]);

  return (
    <Stack maxWidth={settings.themeStretch ? false : 'lg'}>
      <Grid container>
        <Grid item md={8}>
          <Card sx={{ p: 2 }}>
            <FormProvider methods={methods} onSubmit={onSubmit}>
              <Typography variant="h5" marginLeft={2} mt={1}>Update Info</Typography>
              <Grid container spacing={2} pt={3} px={2}>
                <Grid item md={6} xs={12}>
                  <RHFTextField
                    name="name"
                    label="Name"
                    type="text"
                    placeholder="Enter Name"
                    pattern="^[A-Za-z\s]+$"
                    required
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <RHFTextField
                    sx={{ width: '100%' }}
                    name="phone"
                    type="string"
                    label="Phone"
                    placeholder="Enter phone number"
                    onInput={(e) => {
                      const input = e.target.value.replace(/\D/g, '');
                      const limitedInput = input.slice(0, 10);
                      e.target.value = limitedInput;
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container px={2} py={3}>
                <Grid item md={12} xs={12}>
                  <RHFTextField
                    name="email"
                    label="Email"
                    type="string"
                    placeholder="Enter email address"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              </Grid>
              <Typography variant="caption" px={2} fontWeight='bold' >
                Update Password
              </Typography>
              <Grid container py={2} px={2}>
                <Grid item md={6} xs={12}>
                  <RHFTextField
                    name="old_password"
                    label=" Old Password"
                    placeholder="Password"
                    required
                    InputLabelProps={{ shrink: true }}
                    type={password.value ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={password.onToggle} edge="end">
                            <Iconify
                              icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'}
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item md={6} pl={!isMobile && 2} xs={12} pt={isMobile && 2}>
                  <RHFTextField
                    name="new_password"
                    label=" New Password"
                    placeholder="Password"
                    required
                    InputLabelProps={{ shrink: true }}
                    type={password.value ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={password.onToggle} edge="end">
                            <Iconify
                              icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'}
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <Box display="flex" alignItems="center" justifyContent="flex-end"mr={2} mb={2}>
                <LoadingButton
                  color="success"
                  type="submit"
                  size="medium"
                  variant="contained"
                  loading={isSubmitting}
                >
                  Update Changes
                </LoadingButton>
              </Box>
            </FormProvider>
          </Card>
        </Grid>
      </Grid>
    </Stack>
  );
}
