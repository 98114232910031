import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import CopyToClipboard from 'react-copy-to-clipboard';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import { alpha } from '@mui/material/styles';
import ShareIcon from '@mui/icons-material/Share';
import { Button, CardMedia, Typography } from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import { useResponsive } from 'src/hooks/use-responsive';

// import { updateUserProfile } from 'src/server/api/auth';
import copy from 'src/assets/other/copy2.png';
import { frontEndUrl } from 'src/server/api/http';

// import { FileUploadCustom } from 'src/components/CustomFields/ImageUpload';

// ----------------------------------------------------------------------

export default function JoinedAndReferral() {
  const { MemberProfileDetails } = useSelector((state) => ({
    loading: state.member.loading,
    MemberProfileDetails: state.member.memberProfileDetails.member,
  }));
  const { enqueueSnackbar } = useSnackbar();

  // console.log(MemberProfileDetails);
  const isMobile = useResponsive('down', 'md');
  const handleClick = () => {
    const memberId = MemberProfileDetails?.member_id; // Extract member ID if available
    const link = `${paths.auth.register}?ref_id=${memberId}`; // Construct the link with member ID

    if (memberId) {
      window.open(link, '_blank'); // Open the link in a new tab
    } else {
      // Handle the case where member ID is not available (optional)
      console.warn('Member ID not available for referral link.');
    }
  };
  const handleCopy = () => {
    enqueueSnackbar('Link copied!', { variant: 'success' });
  };
  return (
    <Stack flexDirection={isMobile ? 'column' : 'row'} spacing={3}>
      <Stack flex={4}>
        {/* <Card
          sx={{
            p: 3,
            mb: 3,
            // height: MemberProfileDetails?.referral_member && '100%',
            border: (theme) => `1px dashed ${alpha(theme.palette.grey[500], 0.2)}`,
            opacity: 0.72,
          }}
        >
          <Stack mb={2}>
            <Typography variant="body2" color="#919EAB">
              Joined on
            </Typography>
            <Typography variant="body2">
              {moment(MemberProfileDetails?.createdAt).format('DD MMM YYYY h:mm A')}
            </Typography>
          </Stack>
          {MemberProfileDetails?.wallet_address_last_updated_at && (
            <Stack mb={2}>
              <Typography variant="body2" color="#919EAB">
                Wallet address last changed{' '}
              </Typography>
              <Typography variant="body2">
                {' '}
                {moment(MemberProfileDetails?.wallet_address_last_updated_at).format(
                  'DD MMM YYYY h:mm A'
                )}
              </Typography>
            </Stack>
          )}
          <Stack>
            <Typography variant="body2" color="#919EAB">
              You are only able to withdraw funds from your winbuks wallet 24 hours after changing
              your wallet address.
            </Typography>
          </Stack>
        </Card> */}
        {!MemberProfileDetails?.is_club_member &&(
          <Card
            sx={{
              p: 3,
              pb: 2,
              border: (theme) => `1px dashed ${alpha(theme.palette.grey[500], 0.2)}`,
            }}
          >
            <Stack>
              <Typography variant="body2" color="#919EAB">
                To obtain Club Memberships and earn Club Incomes, simply refer three members to join
                our system.
              </Typography>
            </Stack>
            <Stack flexDirection="row" mt={9} justifyContent="space-between">
              <Stack gap={1.5} flexDirection="row">
                <CopyToClipboard
                  // text={`${paths.auth.register}?ref='${MemberProfileDetails?.member_id}`}
                  text={`${frontEndUrl}${paths.auth.register}?ref_id=${MemberProfileDetails?.member_id}`}
                  onCopy={handleCopy}
                >
                  <Button
                    size="small"
                    variant="contained"
                    startIcon={
                      <CardMedia component="img" src={copy} alt="copy" sx={{ width: '15px' }} />
                    }
                    sx={{
                      width: '160px',
                      backgroundColor: '#00A76F',
                      height: '34px',
                      '&:hover': { backgroundColor: '#047f56' },
                    }}
                  >
                    Copy Referral Link{' '}
                  </Button>
                </CopyToClipboard>
                <Button
                  size="small"
                  variant="outlined"
                  startIcon={<ShareIcon />}
                  onClick={handleClick}
                  sx={{ color: '#00A76F', width: '110px', height: '34px' }}
                >
                  Share Link
                </Button>
              </Stack>
            </Stack>
          </Card>
        )}
      </Stack>
    </Stack>
  );
}
