import React from 'react';

import { Container } from '@mui/material';

import { MetaHelmet } from 'src/layouts/Helmet/Helmet';

import { useSettingsContext } from 'src/components/settings';

import SingleView from './MemberView';

function MemberView() {
  const settings = useSettingsContext();
  return (
    <>
      <MetaHelmet title="Winbuks" miniDescription='Member View' />
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <SingleView />
      </Container>
    </>
  );
}

export default MemberView;
