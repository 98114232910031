import { createAsyncThunk } from '@reduxjs/toolkit';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const createClub = createAsyncThunk(
  'club/create',
  async ({ state, dispatch, handleClose, handleClear }) => {
    try {
      const URL = `/club/admin/new`;
      const response = await post(URL, state);
      if (response) {
        handleClose();
        handleClear();
        dispatch(activeSnack({ type: 'success', message: 'Club created successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllClubs = createAsyncThunk(
  'club/list',
  async ({ page, search, dispatch, limit }) => {
    try {
      const response = await get(
        `/club/admin/all?page=${page && page}&search=${search || ''}&limit=${limit || 5}`
      );
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const getAllClubsAdmin = createAsyncThunk(
  'club/admin/list',
  async ({ page, search, dispatch, limit }) => {
    try {
      const response = await get(
        `/club/admin/all?page=${page && page}&search=${search || ''}&limit=${limit || 5}`
      );
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getClubDetails = createAsyncThunk('club/single', async ({ clubId, dispatch }) => {
  try {
    const response = await get(`/club/admin/${clubId}`);
    if (response) {
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const updateClubDetails = createAsyncThunk(
  'club/update',
  async ({ state, clubId, dispatch, handleClose, handleClear }) => {
    try {
      const URL = `/club/admin/${clubId}`;

      const response = await put(URL, state);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        dispatch(activeSnack({ type: 'success', message: 'Club updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteClub = createAsyncThunk(
  'club/delete',
  async ({ clubId, dispatch, handleCloseDeleteDialog }) => {
    try {
      const response = await del(`/club/admin/${clubId}/`);
      if (response) {
        handleCloseDeleteDialog();
        dispatch(activeSnack({ type: 'success', message: 'Club deleted Successfully' }));
        return response?._id;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
