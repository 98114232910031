import PropTypes from 'prop-types';

import PersonIcon from '@mui/icons-material/Person';
import { Paper, Stack, Typography } from '@mui/material';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import MarkEmailUnreadRoundedIcon from '@mui/icons-material/MarkEmailUnreadRounded';

// ----------------------------------------------------------------------

export default function ReferredOnPaymentDetails({ memberDetails }) {
  return (
    <Paper
      variant="outlined"
      sx={{
        height: 230,
        display: 'flex',
        flexDirection: 'column',
        // border: '1px dashed #ccee',
        backgroundColor: '#1d2a35',

        py: 3.5,
        px: 2,
        borderRadius: 3,
      }}
    >
      <Stack flexDirection="" alignItems="start" justifyContent="start">
        {memberDetails?.referral_member ? (
          <Stack>
            <Typography sx={{ color: '#919EAB', fontSize: 13, pb: 1 }}>Referred by</Typography>
            <Stack gap={1} ml={1}>
              <Stack flexDirection="row" alignItems="center">
                <DeviceHubIcon sx={{ fontSize: 14, color: 'white' }} fontSize="small" /> &nbsp;
                <Typography variant="body2" sx={{ color: 'white' }}>
                  {memberDetails?.referral_member?.member_id}
                </Typography>
              </Stack>
              <Stack flexDirection="row" alignItems="center">
                <PersonIcon sx={{ fontSize: 14, color: 'white' }} fontSize="small" /> &nbsp;
                <Typography variant="body2" sx={{ color: 'white' }}>
                  {memberDetails?.referral_member?.name}
                </Typography>
              </Stack>
              <Stack flexDirection="row" alignItems="center">
                <MarkEmailUnreadRoundedIcon
                  sx={{ fontSize: 14, color: 'white' }}
                  fontSize="small"
                />{' '}
                &nbsp;
                <Typography variant="body2" sx={{ color: 'white' }}>
                  {' '}
                  {memberDetails?.referral_member?.email}
                </Typography>
              </Stack>
              <Stack flexDirection="row" alignItems="center">
                <LocalPhoneRoundedIcon sx={{ fontSize: 14, color: 'white' }} fontSize="small" />{' '}
                &nbsp;
                <Typography variant="body2" sx={{ color: 'white' }}>
                  {memberDetails?.referral_member?.phone}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        ) : (
          <Stack>
            <Typography sx={{ color: '#919EAB', fontSize: 13, pb: 1 }}>Referred by</Typography>

            <Typography mt={8} sx={{ color: '#919EAB', fontSize: 13, pb: 1, textAlign: 'center' }}>
              Nil
            </Typography>
          </Stack>
        )}
        {/* <Stack direction="row" alignItems="center" justifyContent="start" py={3}>
          <Button sx={{ color: '#007867', backgroundColor: '#00A76F14', p: 2 }} size="small">
            {' '}
            <ContentCopyIcon fontSize="small" sx={{ fontSize: 16 }} />
            &nbsp; copy Member`s Referral Link
          </Button>
        </Stack> */}
      </Stack>
    </Paper>
  );
}
ReferredOnPaymentDetails.propTypes = {
  memberDetails: PropTypes.object,
};
