import { useState } from 'react';
import PropTypes from 'prop-types';

import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import PersonIcon from '@mui/icons-material/Person';
import { Box, CardMedia, Typography } from '@mui/material';

import { USDT } from 'src/hooks/use-USDT';
import { useResponsive } from 'src/hooks/use-responsive';

import club1 from 'src/assets/other/club1.png';
import club2 from 'src/assets/other/club2.png';
import club3 from 'src/assets/other/club3.png';
import club4 from 'src/assets/other/club4.png';
import club5 from 'src/assets/other/club5.png';

import EditClubDialog from './EditClubDialog';

// ----------------------------------------------------------------------

export default function AllClub({ clubList, dashboard }) {
  const imageArray = [club1, club2, club3, club4, club5];
  const isMobile = useResponsive('down', 'md');

  const combinedArray = imageArray.map((image, index) => ({
    image,
    club: clubList && clubList[index] ? clubList[index] : null,
  }));

  // console.log('club', clubList);
  // console.log(combinedArray);
  const [isOpenDialog, setIsOpenDialog] = useState({ status: false, updateValues: {} });

  return (
    <>
      <EditClubDialog
        open={isOpenDialog?.status}
        setOpen={setIsOpenDialog}
        isUpdate={isOpenDialog?.updateValues}
      />
      {/* <Card sx={{ pt: 2 }}> */}
      {/* <Box display="flex" alignItems="center" px={3}>
          <Box p={0}>
            <CardMedia src={imgClubIncome} component="img" sx={{ height: 100, width: 100 }} />
          </Box>
          <Box pl={4}>
            <Typography variant="subtitle2">Clubs</Typography>
            <Typography variant="h6" py={0.5}>
              {!dashboard && `500 ${USDT}`}
            </Typography>
            <Typography sx={{ color: '#637381', fontSize: 12 }}>
              See how much is in your wallet for every club.
            </Typography>
          </Box>
        </Box> */}
      <Stack
        spacing={2}
        sx={{ p: 2 }}
        flexDirection={isMobile ? 'column' : 'row'}
        justifyContent="space-between"
      >
        {(combinedArray || []).map((contact, index) => (
          <>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              // flexDirection="column"
              flexDirection={isMobile ? 'row' : 'column'}
              sx={{
                position: 'relative',
                // '&::after': {
                //   content: '""',
                //   position: 'absolute',
                //   top: 0,
                //   bottom: 0,
                //   // left: '100%',
                //   right: '-25%',
                //   // transform: 'translateX(-25%)',

                //   width: '1px',
                //   background:
                //     'repeating-linear-gradient(to bottom, #dedede, #dedede 2px, transparent 2px, transparent 4px)',
                //   display: index === combinedArray.length - 1 ? 'none' : 'block',
                // },
              }}
            >
              <>
                <Box display="flex" alignItems="center" justifyContent="center" key={contact.id}>
                  <Box>
                    <CardMedia
                      src={contact?.image}
                      component="img"
                      sx={{ width: 37, height: 37, mr: 1 }}
                    />
                  </Box>

                  <Box>
                    {!dashboard && (
                      <Tooltip title="Edit club details">
                        <Typography
                          variant="caption"
                          fontWeight="bold"
                          sx={{ cursor: 'pointer' }}
                          onClick={() => setIsOpenDialog({ status: true, updateValues: contact })}
                        >
                          {contact?.club?.title} {/* <Tooltip title="Edit club details"> */}
                          {/* {!dashboard && (
                          <CreateRoundedIcon
                            fontSize="14px"
                            sx={{ fontSize: 18, cursor: 'pointer', color: '#919EABCC' }}
                          />
                        </Tooltip>
                      )} */}
                        </Typography>
                      </Tooltip>
                    )}
                    {dashboard && (
                      <Typography variant="caption" fontWeight="bold">
                        {contact?.club?.title} {/* <Tooltip title="Edit club details"> */}
                        {/* {!dashboard && (
                          <CreateRoundedIcon
                            fontSize="14px"
                            sx={{ fontSize: 18, cursor: 'pointer', color: '#919EABCC' }}
                          />
                        </Tooltip>
                      )} */}
                      </Typography>
                    )}

                    <Stack flexDirection="row" alignItems="center">
                      <PersonIcon fontSize="14px" sx={{ color: '#919EABCC' }} />
                      {/* <ListItemText
                        primary={`${dashboard === false ? `${contact?.club?.memberClubCount} ` : ''}`}
                        //                     secondary={` limit up to ${USDT}
                        // ${contact?.club?.limit_amount}`}
                      /> */}
                      <Typography variant="subtitle2" sx={{ color: '#919EABCC' }}>
                        {/* {`${dashboard === false ? `${contact?.club?.memberClubCount} ` : ''}`} */}
                     {contact?.club?.activeMemberClubCount|| 0} /{contact?.club?.memberClubCount|| 0}
                      </Typography>
                    </Stack>
                  </Box>
                  {/* <Box>
                {' '}
                <Typography>
                  limit up to {USDT} {contact?.limit_amount}
                </Typography>
              </Box> */}
                </Box>
                <Box>
                  <Box>
                    <Tooltip title="Balance">
                      <Typography variant="subtitle2">
                        {`${contact?.club?.wallet_balance?.toFixed(2) || 0}  `} {USDT}
                      </Typography>
                    </Tooltip>
                  </Box>
                </Box>
              </>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              // flexDirection="column"
              flexDirection={isMobile ? 'row' : 'column'}
              sx={{
                position: 'relative',
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  bottom: 0,
                  // left: '100%',
                  right: '-25%',
                  // transform: 'translateX(-25%)',

                  width: '1px',
                  background:
                    'repeating-linear-gradient(to bottom, #dedede, #dedede 2px, transparent 2px, transparent 4px)',
                  display: index === combinedArray.length - 1 ? 'none' : 'block',
                },
              }}
            >
              {' '}
            </Box>
          </>
        ))}
      </Stack>
      {/* </Card> */}
    </>
  );
}

AllClub.propTypes = {
  clubList: PropTypes.array,
  dashboard: PropTypes.bool,
};
