import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';

import { Tab, Tabs, alpha } from '@mui/material';

import Label from 'src/components/label';

// ----------------------------------------------------------------------

export default function EnquirySort({ sort, setSort, allList }) {
  const theme = useTheme();
  // console.log(allList);
  const TABS = [
    {
      value: '',
      label: 'All',
      color: 'default',
      count: allList?.enquirySortCounts?.allEnquiryCount || 0,
    },
    {
      value: 'NEW',
      label: 'New',
      color: 'info',
      count: allList?.enquirySortCounts?.totalNewEnquiryCount || 0,
    },
    {
      value: 'OPENED',
      label: 'Opened',
      color: 'success',
      count: allList?.enquirySortCounts?.totalOpenedEnquiryCount || 0,
    },
    {
      value: 'PENDING',
      label: 'Pending',
      color: 'warning',
      count: allList?.enquirySortCounts?.totalPendingEnquiryCount || 0,
    },
    {
      value: 'CLOSED',
      label: 'Closed',
      color: 'error',
      count: allList?.enquirySortCounts?.totalClosedEnquiryCount || 0,
    },
    {
      value: 'CONFIRMED',
      label: 'Confirmed',
      color: 'success',
      count: allList?.enquirySortCounts?.totalConfirmedEnquiryCount || 0,
    },
  ];

  return (
    <Tabs
      value={sort}
      onChange={(e, newValue) => {
        setSort(newValue);
      }}
      sx={{
        px: 2.5,
        boxShadow: `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
      }}
    >
      {TABS?.map((tab) => (
        <Tab
          key={tab.value}
          value={tab.value}
          label={tab.label}
          iconPosition="end"
          icon={
            <Label
              variant={((tab.value === 'all' || tab.value === sort) && 'filled') || 'soft'}
              color={tab.color}
            >
              {tab.count}
            </Label>
          }
        />
      ))}
    </Tabs>
  );
}

EnquirySort.propTypes = {
  setSort: PropTypes.func,
  sort: PropTypes.string,
  allList: PropTypes.object,
};
