import dayjs from 'dayjs';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Grid } from '@mui/material';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';

import { useParams } from 'src/routes/hooks';
import { paths } from 'src/routes/routes/paths';

import { useResponsive } from 'src/hooks/use-responsive';

import { getAllClubs } from 'src/server/api/club';
import coverImg from 'src/assets/other/set-role.jpg';
import memberProfileImg from 'src/assets/other/profile.png';
import {
  getAllMembers,
  getMemberDetails,
  getMemberClubIncomeTransactions,
} from 'src/server/api/member';

import { useTable } from 'src/components/table';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';

import ContactShow from 'src/sections/Member/MemberSingleView/ContactShow';
import MiniWidgets from 'src/sections/Member/MemberSingleView/MiniWidgets';
import PaymentShow from 'src/sections/Member/MemberSingleView/PaymentShow';
import MemberCover from 'src/sections/Member/MemberSingleView/member-cover';
import JoinedOnDetails from 'src/sections/Member/MemberSingleView/JoinedOnDetails';
import WalletAddressDetailsAdmin from 'src/sections/Member/wallet-address-details';
import VerifiedDetails from 'src/sections/Member/MemberSingleView/VerifiedDetails.1';
import ReferredByDetails from 'src/sections/Member/MemberSingleView/ReferredByDetails';
import UpdateAdminModalDialog from 'src/sections/Member/MemberSingleView/updateAdminModal';
import ReferralMember from 'src/sections/Member/MemberSingleView/miniTables/ReferralMembersTable';
import ReferredOnPaymentDetails from 'src/sections/Member/MemberSingleView/ReferredViewOnpayment';
import ClubMemberTransactionsTable from 'src/sections/Member/MemberSingleView/miniTables/ClubMemberTransactionsTable';

// ----------------------------------------------------------------------

export default function SingleView() {
  const dispatch = useDispatch();
  const isMobile = useResponsive('down', 'md');

  const params = useParams();
  const [date, setDate] = useState();
  const tableClubIncome = useTable({ defaultOrderBy: 'createdAt' });
  const limitValueClub = sessionStorage?.getItem(`rowsPerPage_${'referralMember'}`);
  const [openModalDialog, setOpenModalDialog] = useState(false);

  const selectedRangeFormatted = date?.map((dates) => dayjs(dates).format('YYYY-MM-DD'));

  const { memberDetails, club, membersList, clubIncomeTransactions } = useSelector((state) => ({
    loading: state.member.loading,
    memberDetails: state.member.memberDetails,
    club: state.club.clubs?.clubs,
    membersList: state.member.members?.members,
    clubIncomeTransactions: state.member.clubIncomeTransactions?.clubIncomeTransactions,
  }));

  // console.log(club);
  const [searchText, setSearchText] = useState('');

  // const [tableData, setTableData] = useState([]);

  // console.log(tableData);

  useEffect(() => {
    const credentials = {
      memberId: params?.id,
      dispatch,
    };
    dispatch(getMemberDetails(credentials));
  }, [dispatch, params?.id]);

  // useEffect(() => {
  //   setTableData(memberDetails);
  // }, [memberDetails, setTableData]);

  const handleSearchClub = (value) => {
    setSearchText(value);
  };

  const [selectedClub, setSelectedClub] = useState('');
  const [searchClub, setSearchClub] = useState('');

  const handleTextFieldChangeClub = (event) => {
    setSearchClub(event.target.value);
  };
  const handleAutocompleteChangeClub = (e, newValue) => {
    setSelectedClub(newValue || '');
  };

  const optionClub = [
    { label: 'All', value: '' },
    ...(club || []).map((results) => ({
      label: results?.title,
      value: results?._id,
    })),
  ];

  useEffect(() => {
    if (memberDetails?.is_verified === true) {
      const clubValues = {
        page: '',
        search: searchClub,
        dispatch,
        limit: '',
      };
      dispatch(getAllClubs(clubValues));
    }
  }, [dispatch, memberDetails?.is_verified, searchClub]);

  useEffect(() => {
    if (memberDetails?.is_verified === true && params?.id) {
      const incomeTransactionValues = {
        page: tableClubIncome.page + 1,
        dispatch,
        date,
        selectedClub: selectedClub?.value,
        limit: tableClubIncome?.rowsPerPage || limitValueClub,
        id: params?.id,
      };
      dispatch(getMemberClubIncomeTransactions(incomeTransactionValues));
    }
  }, [
    memberDetails,
    dispatch,
    date,
    selectedClub?.value,
    tableClubIncome?.page,
    tableClubIncome?.rowsPerPage,
    limitValueClub,
    params?.id,
  ]);

  // console.log(selectedRangeFormatted);

  const limitValue = sessionStorage?.getItem(`rowsPerPage_${'referralMember'}`);

  const table = useTable({ defaultOrderBy: 'createdAt' });
  useEffect(() => {
    const credentials = {
      page: table.page + 1,
      search: searchText,
      dispatch,
      limit: table?.rowsPerPage || limitValue,
      referralMember: params?.id,
    };
    dispatch(getAllMembers(credentials));
  }, [dispatch, table.page, table?.rowsPerPage, limitValue, searchText, params?.id]);

  return (
    <>
      {openModalDialog && (
        <UpdateAdminModalDialog
          setOpen={setOpenModalDialog}
          open={openModalDialog}
          isUpdate={memberDetails}
          memberDetails={memberDetails}
          // setIsUpdate={setInvestorData}
        />
      )}
      <CustomBreadcrumbs
        heading="Member Details"
        links={[
          { name: 'Dashboard', href: paths.dashboard.root },
          { name: 'Member', href: paths.dashboard?.member },
          { name: memberDetails?.name },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <Grid container>
        {/* <Grid item md={8} xs={12}>
          <Card
            sx={{
              mb: 3,
              height: isMobile ? 'auto' : 230,
            }}
          >
            <MemberCover
              country_code={memberDetails?.country_code}
              username={memberDetails?.user?.username}
              phone={memberDetails?.user?.phone}
              email={memberDetails?.user?.email}
              avatarUrl={memberDetails?.profilePic?.url || memberProfileImg}
              coverUrl={memberDetails.coverUrl || coverImg}
              verified={memberDetails?.is_verified}
              paid={memberDetails?.is_paid}
            />
          </Card>
        </Grid>

        <Grid md={4} pl={isMobile ? 0 : 2} xs={12} height='100%'>
          {memberDetails.is_verified === true && (
            <ReferredOnPaymentDetails memberDetails={memberDetails} />
          )}
          {memberDetails?.is_verified === false && memberDetails?.is_paid === true && (
            <PaymentShow memberDetails={memberDetails} />
          )}
          {memberDetails.is_paid === false && <ContactShow memberDetails={memberDetails} />}
        </Grid> */}
        <Grid
          item
          md={12}
          xs={12}
          gap={3}
          sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', mb: 3 }}
        >
          <Card
            sx={{
              // mb: 3,
              flex: 8,
              // height: isMobile ? 'auto' : 230,
            }}
          >
            <MemberCover
              createdAt={memberDetails?.createdAt}
              setOpenModalDialog={setOpenModalDialog}
              country_code={memberDetails?.country_code}
              memberID={memberDetails?.member_id}
              username={memberDetails?.user?.username}
              name={memberDetails?.user?.name}
              phone={memberDetails?.user?.phone}
              email={memberDetails?.user?.email}
              avatarUrl={memberDetails?.profile_photo?.url || memberProfileImg}
              coverUrl={memberDetails.coverUrl || coverImg}
              verified={memberDetails?.is_verified}
              paid={memberDetails?.is_paid}
            />
          </Card>
          <Stack flex={4} height="100%">
            {memberDetails.is_verified === true && (
              <ReferredOnPaymentDetails memberDetails={memberDetails} />
            )}
            {memberDetails?.is_verified === false && memberDetails?.is_paid === true && (
              <PaymentShow memberDetails={memberDetails} />
            )}
            {memberDetails.is_paid === false && <ContactShow memberDetails={memberDetails} />}
          </Stack>
        </Grid>

        <MiniWidgets memberDetails={memberDetails} />
        <Grid
          md={12}
          py={3}
          xs={12}
          gap={3}
          sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}
        >
          <Stack flex={8}>
            <WalletAddressDetailsAdmin memberDetails={memberDetails} />
          </Stack>
          <Stack flex={4} height="100%">
            {memberDetails?.is_verified === true ? (
              <VerifiedDetails memberDetails={memberDetails} />
            ) : (
              <>
                {memberDetails?.referral_member?.name && (
                  <Stack pb={2} height="100%">
                    <ReferredByDetails memberDetails={memberDetails} />
                  </Stack>
                )}
                <Stack height="100%">
                  <JoinedOnDetails memberDetails={memberDetails} />
                </Stack>{' '}
              </>
            )}
          </Stack>
        </Grid>
        {/* <Grid md={8} py={isMobile ? 1 : 3} pl={!isMobile && 3} xs={12}>
        </Grid> */}

        {/* <Grid
          md={12}
          xs={12}
          pb={isMobile && 1}
          sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}
          gap={3}
        >
          <Stack flex={8}>
            <UpdateMember memberDetails={memberDetails} />
          </Stack> */}
        {/* <Stack flex={4} height="100%">
            {memberDetails?.is_verified === true ? (
              <VerifiedDetails memberDetails={memberDetails} />
            ) : (
              <>
                {memberDetails?.referral_member?.name && (
                  <Stack pb={2} height="100%">
                    <ReferredByDetails memberDetails={memberDetails} />
                  </Stack>
                )}
                <Stack height="100%">
                  <JoinedOnDetails memberDetails={memberDetails} />
                </Stack>{' '}
              </>
            )}
          </Stack> */}
        {/* </Grid> */}
        {/* <Grid md={8} xs={12} pb={isMobile && 1}>
          <UpdateMember memberDetails={memberDetails} />
        </Grid>

        <Grid md={4} pl={!isMobile && 3} xs={12} height="100%">
          {memberDetails?.is_verified === true ? (
            <VerifiedDetails memberDetails={memberDetails} />
          ) : (
            <>
              <Stack>
                {memberDetails?.referral_member?.name && (
                  <ReferredByDetails memberDetails={memberDetails} />
                )}
              </Stack>{' '}
              <Stack pt={2}>
                <JoinedOnDetails memberDetails={memberDetails} />
              </Stack>{' '}
            </>
          )}
        </Grid> */}
      </Grid>
      {memberDetails?.is_verified === true && (
        <Stack py={3}>
          <Grid container>
            <Grid md={6} lg={6} xs={12}>
              <Grid className="table-remove-scroll">
                <ReferralMember
                  search={setSearchText}
                  table={table}
                  title="Referral Members"
                  subheader=""
                  tableData={membersList}
                  tableLabels={[
                    { id: 'date', label: 'Date' },
                    { id: 'member', label: 'Member' },
                    { id: 'contact', label: 'Contact' },
                    { id: '' },
                  ]}
                />
              </Grid>
            </Grid>
            <Grid md={6} lg={6} pl={!isMobile && 3.5} xs={12} pt={isMobile && 1}>
              <Grid className="table-remove-scroll-club-member">
                <ClubMemberTransactionsTable
                  date={date}
                  setDate={setDate}
                  dateFormatted={selectedRangeFormatted}
                  clubSearch={handleSearchClub}
                  handleTextFieldChangeClub={handleTextFieldChangeClub}
                  handleAutocompleteChangeClub={handleAutocompleteChangeClub}
                  optionClub={optionClub}
                  table={tableClubIncome}
                  title="Club Income Transaction"
                  subheader=""
                  tableData={clubIncomeTransactions}
                  tableLabels={[
                    { id: 'date', label: 'Date' },
                    { id: 'description', label: 'Description' },
                    { id: 'amount', label: 'Amount ' },
                    { id: 'club', label: 'Club' },
                    // { id: '' },
                  ]}
                />
              </Grid>
            </Grid>
          </Grid>
        </Stack>
      )}
    </>
  );
}
