import Container from '@mui/material/Container';

import { paths } from 'src/routes/routes/paths';

import { useGetRoles } from 'src/hooks/useHandleSessions';

import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';

import AdminProfile from './account-admin';
import MemberProfile from './account-member';
// ----------------------------------------------------------------------

// const TABS = [
//   {
//     value: 'general',
//     label: 'General',
//     icon: <Iconify icon="solar:user-id-bold" width={24} />,
//   },

//   {
//     value: 'security',
//     label: 'Security',
//     icon: <Iconify icon="ic:round-vpn-key" width={24} />,
//   },
// ];

// ----------------------------------------------------------------------

export default function AccountView() {
  // const location = useLocation();
  const role = useGetRoles()?.role;

  const settings = useSettingsContext();
  // const { MemberProfileDetails } = useSelector((state) => ({
  //   loading: state.member.loading,
  //   MemberProfileDetails: state.member.memberProfileDetails.member,
  // }));
  // console.log(MemberProfileDetails);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <CustomBreadcrumbs
        heading="Account"
        links={[
          { name: 'Dashboard', href: paths.dashboard.root },
          { name: 'User', href: paths.dashboard.root },
          { name: 'Account' },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      {role === 'admin' ? <AdminProfile /> : <MemberProfile />}
    </Container>
  );
}
