import { useSnackbar } from 'notistack';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// @mui
import { Container } from '@mui/material';

import { useGetRoles } from 'src/hooks/useHandleSessions';

import { MetaHelmet } from 'src/layouts/Helmet/Helmet';
import {
  getDashboardData,
  getIncomeChartData,
  getReferralChartData,
} from 'src/server/api/dashboard';

import { useSettingsContext } from 'src/components/settings';
import DashboardLoading from 'src/components/loading/DashboardLoading';

import DashboardContent from 'src/sections/app/admin';

// components

// action

// sections

// ----------------------------------------------------------------------

export default function DashboardAdminApp() {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [selectedYear, setSelectedYear] = useState('2024');
  const { role } = useGetRoles();
  // console.log(role)
  const { dashLoading } = useSelector((state) => ({
    dashLoading: state.dashboard.loading,
  }));

  useEffect(() => {
    const credentials = {
      enqueueSnackbar,
    };
    dispatch(getDashboardData(credentials));
  }, [dispatch, enqueueSnackbar]);

  // useEffect(() => {
  //   const credentialsYear = {
  //     enqueueSnackbar,
  //     year: selectedYear,
  //   };

  //   dispatch(getReferralChartData(credentialsYear));
  //   dispatch(getIncomeChartData(credentialsYear));
  // }, [dispatch, enqueueSnackbar, selectedYear]);
  useEffect(() => {
    if (role !== 'admin') {
      const credentialsYear = {
        enqueueSnackbar,
        year: selectedYear,
      };

      dispatch(getReferralChartData(credentialsYear));
      dispatch(getIncomeChartData(credentialsYear));
    }
  }, [dispatch, enqueueSnackbar, selectedYear, role]);

  const settings = useSettingsContext();

  return (
    <>
      <MetaHelmet title="Winbuks" miniDescription="Admin Dashboard" />

      <Container maxWidth={settings.themeStretch ? false : 'xl'} sx={{ mt: 4 }}>
        {!dashLoading ? (
          <DashboardContent setSelectedYear={setSelectedYear} selectedYear={selectedYear} />
        ) : (
          <DashboardLoading />
        )}
      </Container>
    </>
  );
}
