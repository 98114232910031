import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router';
import React, { useState, useEffect } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { Card, CardMedia, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';

import copy from 'src/assets/other/bluecopy.png';
import wallet from 'src/assets/other/wallet.webp';
import { deleteWalletAddressAdmin, getAllWalletAddressAdmin } from 'src/server/api/walletAddress';

import Iconify from 'src/components/iconify';
import { ConfirmDialog } from 'src/components/custom-dialog';

import WalletAddressDialog from 'src/sections/walletAddress/walletAdressModal';

// ----------------------------------------------------------------------

export default function WalletAddressDetailsAdmin({ memberDetails }) {
  const dispatch = useDispatch();
  const confirm = useBoolean();
  const params = useParams();
  // const role = useGetRoles()?.role;
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useResponsive('down', 'md');

  const [openWalletAddressId, setOpenWalletAddressId] = useState({});
  const [openWalletAddressDialog, setOpenWalletAddressDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null); // State to hold the selected item data

  const { memberWalletAddress } = useSelector((state) => ({
    loading: state.referrals.loading,
    memberProfileDetails: state.member.memberProfileDetails,
    memberClubForMember: state.memberClub.memberClubsForMember?.memberClubs,
    // memberWalletAddress: state.walletAddress.walletAddresses.walletAddresses,
    memberWalletAddress: state.walletAddress?.walletAddresses?.memberWalletAddresses,
  }));
  // console.log(memberDetails);

  // console.log(role);

  useEffect(() => {
    const credentials = {
      page: '',
      search: '',
      id: params?.id,
      limit: '',
      dispatch,
    };
    if (params?.id) {
      dispatch(getAllWalletAddressAdmin(credentials));
    }
  }, [dispatch, params?.id]);
  const handleDeleteConfirmClick = (id) => {
    const credentials = {
      dispatch,
      walletId: id,
      id: params?.id,
    };
    // console.log(id);

    dispatch(deleteWalletAddressAdmin(credentials));
    confirm.onFalse();
  };
  const handleDelete = (item) => {
    setSelectedItem(item); // Set the selected item
    confirm.onTrue(); // Open the confirmation dialog
  };
  const handleCopy = () => {
    enqueueSnackbar('Link copied!', { variant: 'success' });
  };

  return (
    <>
      {openWalletAddressDialog && (
        <WalletAddressDialog
          memberId={memberDetails?._id}
          isUpdate={openWalletAddressId}
          setOpenWalletAddressId={setOpenWalletAddressId}
          setOpen={setOpenWalletAddressDialog}
          open={openWalletAddressDialog}
        />
      )}
      <Card
        sx={{
          py: 3,
          // minHeight: 300,
          height: '100%',
        }}
      >
        <Stack
          px={3}
          spacing={3}
          direction={{ xs: 'row', md: 'row' }}
          alignItems={{ xs: 'flex-end', md: 'center' }}
          justifyContent="space-between"
        >
          <Typography variant="h6">Wallet Address</Typography>
          <Button
            type="submit"
            size="small"
            startIcon={<Iconify icon="mingcute:add-line" />}
            // onClick={setOpenWalletAddressDialog(true)}
            onClick={() => {
              setOpenWalletAddressDialog(true);
            }}
            sx={{ flexShrink: 0, color: '#00A76F' }}
          >
            Add Wallet
          </Button>
        </Stack>
        <Box
          sx={{
            pt: 2,
            px: 2,
            mx: 1,
            height: 230,
            overflowY: 'auto',
            WebkitOverflowScrolling: 'touch',
            '&::-webkit-scrollbar': {
              width: '8px', // Width of the scrollbar
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'grey', // Color of the scrollbar thumb
              borderRadius: '15px', // Border radius of the scrollbar thumb
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: '#F5F5F5', // Color of the scrollbar track
              borderRadius: '15px', // Border radius of the scrollbar track
            },
          }}
        >
          {memberWalletAddress && memberWalletAddress.length > 0 ? (
            <Stack spacing={1} mb={2}>
              {(memberWalletAddress || []).map((item, index) => (
                <Card
                  key={index}
                  sx={{
                    border: '1px solid #00B8D9 ',
                    borderRadius: '10px',
                    // boxShadow: 'box-shadow: 4px 4px 4px 0px #00000040',
                    boxShadow:
                      '6px 6px 2px 0 rgb(91 94 98 / 20%), 14px 16px 24px -4px rgb(120 127 133 / 12%)',
                  }}
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    padding={2}
                    overflow={isMobile && 'scroll'}
                    width={isMobile ? 300 : 'auto'}
                  >
                    <Typography>{item?.title ? `${item?.title} : ` : ''}{item?.wallet_id}</Typography>
                    <Stack
                      direction="row"
                      spacing={isMobile ? 0 : 1}
                      padding={isMobile && 1.5}
                      px={isMobile && 1}
                      sx={{
                        position: isMobile && 'absolute',
                        right: isMobile && 0,
                        bgcolor: isMobile && '#f3f2f2',
                      }}
                    >
                      <CopyToClipboard text={item?.wallet_id} onCopy={handleCopy}>
                        <Tooltip title="Copy Wallet">
                          <Button size="small" color="info" sx={{ minWidth: '27px' }}>
                            <CardMedia
                              component="img"
                              src={copy}
                              alt="copy"
                              sx={{ width: '20px' }}
                            />
                          </Button>
                        </Tooltip>
                      </CopyToClipboard>
                      <Tooltip title="Edit Wallet">
                        <Button
                          size="small"
                          color="info"
                          sx={{ minWidth: '27px' }}
                          onClick={() => {
                            setOpenWalletAddressId(item);
                            setOpenWalletAddressDialog(true);
                          }}
                        >
                          <Iconify icon="heroicons-outline:pencil-alt" sx={{ color: 'grey' }} />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Remove Wallet">
                        <Button
                          size="small"
                          color="info"
                          sx={{ minWidth: '27px' }}
                          onClick={() => {
                            handleDelete(item);
                          }}
                        >
                          <Iconify icon="heroicons-outline:trash" sx={{ color: 'grey' }} />
                        </Button>
                      </Tooltip>
                    </Stack>
                  </Stack>
                </Card>
              ))}
            </Stack>
          ) : (
            <Stack flexDirection="row" spacing={2} alignItems="center">
              <Stack alignItems="center" flex={6}>
                <CardMedia component="img" src={wallet} alt="dashboard" sx={{ width: '210px' }} />
              </Stack>
              <Stack alignItems="left" flexDirection="row" justifyContent="center" flex={6}>
                <ErrorOutlineIcon
                  sx={{ marginRight: '5px', color: '#697077', fontSize: '15px', mt: 0.5 }}
                />
                <Typography
                  variant="caption"
                  color="#697077"
                  justifyContent="center"
                  alignItems="center"
                >
                  You can only withdraw funds from your Winbuks wallet 24 hours after updating your
                  wallet address.{' '}
                </Typography>
              </Stack>
            </Stack>
          )}
        </Box>
        {memberDetails?.wallet_address_last_updated_at && (
          <Stack
            mt={1.5}
            flexDirection={isMobile ? 'column' : 'row'}
            width={isMobile ? '85%' : '100%'}
            justifyContent="space-between"
            mx={isMobile && 2}
            // sx={{ position: 'absolute', bottom: 16, right: 16 }}
          >
            <Stack alignItems="center" flexDirection="row" justifyContent="center" flex={7}>
              <ErrorOutlineIcon
                sx={{ marginRight: '5px', color: '#697077', fontSize: '15px', mt: 0.5 }}
              />

              {isMobile ? (
                <Typography
                  variant="caption"
                  color="#697077"
                  justifyContent="center"
                  alignItems="center"
                  mt={0.2}
                >
                  You can only withdraw funds from your Winbuks wallet 24 hours after updating your
                  wallet address.{' '}
                </Typography>
              ) : (
                <Typography
                  variant="caption"
                  color="#697077"
                  justifyContent="center"
                  alignItems="center"
                  mt={0.2}
                >
                  You can only withdraw funds from your Winbuks wallet <br />
                  24 hours after updating your wallet address.{' '}
                </Typography>
              )}
            </Stack>
          </Stack>
        )}
      </Card>
      <ConfirmDialog
        title={`Are you sure want to delete  '${selectedItem ? selectedItem?.address : ''}' ? `}
        content="Are you sure you want to delete this Wallet Address? This action cannot be undone."
        action={
          <Button
            variant="contained"
            sx={{
              color: 'white',
              backgroundColor: '#106E58',
              '&:hover': {
                backgroundColor: '#0b4e3e',
                boxShadow: 'unset',
              },
            }}
            onClick={() => handleDeleteConfirmClick(selectedItem?._id)}
          >
            Delete
          </Button>
        }
        open={confirm.value}
        onClose={confirm.onFalse}
      />
    </>
  );
}

WalletAddressDetailsAdmin.propTypes = {
  memberDetails: PropTypes.any,
};
