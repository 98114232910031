import PropTypes from 'prop-types';

import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Divider from '@mui/material/Divider';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { Stack, Tooltip, Typography, ListItemText } from '@mui/material';

import { fDate, fTime } from 'src/utils/format-time';

import Scrollbar from 'src/components/scrollbar';
import TextTruncate from 'src/components/Typography/TextTruncate';
import { TableNoData, TableHeadCustom, TablePaginationCustom } from 'src/components/table';

import RefMemberSearch from '../referralMemberSearch';

// ----------------------------------------------------------------------

export default function ReferralMembers({
  title,
  subheader,
  tableData,
  table,
  setSearchText,
  tableLabels,
  ...other
}) {
  const totalPage = tableData?.length;
  const notFound = !tableData?.length || !tableData?.length;

  const handleSearch = (value) => {
    setSearchText(value);
  };

  // console.log(setSearchText);

  const identifier = 'referralMember';
  return (
    <Card {...other}>
      <Typography color="#212B36" py={2} pt={4} px={3} sx={{ fontSize: 16, fontWeight: 700 }}>
        Referral Members &nbsp;<Tooltip title="Total Referred Count">({totalPage})</Tooltip>
      </Typography>
      <Stack px={1} py={1}>
        <RefMemberSearch onSearch={handleSearch} />
      </Stack>
      <TableContainer sx={{ overflow: 'unset' }}>
        <Scrollbar>
          <Table sx={{ minWidth: 680 }}>
            <TableHeadCustom headLabel={tableLabels} />

            <TableBody>
              {tableData?.map((row) => (
                <AppNewInvoiceRow key={row.id} row={row} />
              ))}
            </TableBody>
            <TableNoData notFound={notFound} />
          </Table>
        </Scrollbar>
      </TableContainer>
      <TablePaginationCustom
        count={totalPage || 0}
        page={table.page}
        rowsPerPage={table.rowsPerPage}
        onPageChange={table.onChangePage}
        onRowsPerPageChange={table.onChangeRowsPerPage}
        dense={table.dense}
        onChangeDense={table.onChangeDense}
        identifier={identifier}
      />

      <Divider sx={{ borderStyle: 'dashed' }} />
    </Card>
  );
}

ReferralMembers.propTypes = {
  subheader: PropTypes.string,
  tableData: PropTypes.array,
  tableLabels: PropTypes.array,
  title: PropTypes.string,
  table: PropTypes.string,
  setSearchText: PropTypes.any,
};

// ----------------------------------------------------------------------

function AppNewInvoiceRow({ row }) {
  return (
    <TableRow>
      <TableCell sx={{ width: 150 }}>
        <ListItemText
          primary={fDate(row?.createdAt)}
          secondary={fTime(row?.createdAt)}
          primaryTypographyProps={{ typography: 'body2', noWrap: true }}
          secondaryTypographyProps={{
            mt: 0.5,
            component: 'span',
            typography: 'caption',
          }}
        />
      </TableCell>

      <TableCell sx={{ width: 150 }}>
        <ListItemText
          primary={row.member_id}
          secondary={row?.name}
          primaryTypographyProps={{ typography: 'body2', noWrap: true }}
          secondaryTypographyProps={{
            mt: 0.5,
            component: 'span',
            typography: 'caption',
          }}
        />
      </TableCell>

      <TableCell sx={{ width: 25 }}>
        <ListItemText
          primary={<Typography variant="caption">{row.phone}</Typography>}
          secondary={<TextTruncate text={row?.email} width="80px" />}
          primaryTypographyProps={{ typography: 'body2', noWrap: true }}
          secondaryTypographyProps={{
            mt: 0.5,
            component: 'span',
            typography: 'caption',
          }}
        />
      </TableCell>
    </TableRow>
  );
}

AppNewInvoiceRow.propTypes = {
  row: PropTypes.object,
};
