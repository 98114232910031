import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Stack } from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import { useResponsive } from 'src/hooks/use-responsive';
// import { useGetRoles } from 'src/hooks/useHandleSessions';

// import { getAllClubs } from 'src/server/api/club';
import { getAllIncentiveLevels } from 'src/server/api/incentive';
// import { getAllTransactionsAdmin } from 'src/server/api/transaction';

import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';

import IncentiveCard from 'src/sections/incentive/incentiveCard';
import WalletTransferDialog from 'src/sections/income/wallet-Transfer';
import WalletTransferSuccess from 'src/sections/income/WalletTransfer-success-modal';

import MemberIncentivePage from './MemberIncentivePage';

// ----------------------------------------------------------------------

export default function IncentivePage() {
  // const { enqueueSnackbar } = useSnackbar();
  // const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useResponsive('down', 'md');
  // const role = useGetRoles();

  const {  incentiveLevelList } = useSelector(
    (state) => ({
      loading: state.referrals.loading,
      incentiveLevelList: state.incentive.incentiveLevels?.incentiveLevels,
      MemberProfileDetails: state.member.memberProfileDetails,
      memberClubForMember: state.memberClub.memberClubsForMember?.memberClubs,
      settingsData: state.settings.settingsDetails,
    })
  );
  const [openWalletTransferDialog, setOpenWalletTransferDialog] = useState(false);
  const [openWalletTransferSuccess, setOpenWalletTransferSuccess] = useState(false);
  const [openClubId, setOpenClubId] = useState('');
  console.log(setOpenClubId);
  useEffect(() => {
    dispatch(getAllIncentiveLevels(dispatch));
  }, [dispatch]);

  return (
    <>
      {openWalletTransferDialog && (
        <WalletTransferDialog
          setOpen={setOpenWalletTransferDialog}
          open={openWalletTransferDialog}
          setOpenWalletTransferSuccess={setOpenWalletTransferSuccess}
          openClubId={openClubId}
        />
      )}
      {openWalletTransferSuccess && (
        <WalletTransferSuccess
          setOpen={setOpenWalletTransferSuccess}
          open={openWalletTransferSuccess}
        />
      )}
      <CustomBreadcrumbs
        heading="Incentive"
        links={[
          {
            name: 'Dashboard',
            href: paths.dashboard.root,
          },
          {
            name: 'Incentives',
          },
          {
            name: 'List',
          },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <Stack
        mt={3}
        flexDirection={isMobile ? 'column' : 'column'}
        spacing={3}
        width={isMobile ? '100%' : '100%'}
      >
        <IncentiveCard incentiveLevelList={incentiveLevelList}/>
        <MemberIncentivePage disable="disable" />
      </Stack>
    </>
  );
}
