import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import { Stack } from '@mui/material';

import Footer from 'src/sections/website/footer';
import Header from 'src/sections/website/header';

// ----------------------------------------------------------------------

export default function MainLayout({ children }) {
  // const homePage = pathname === '/';

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        // height: 1
      }}
    >
      <Header />

      <Stack
        component="main"
        sx={{
          // flexGrow: 1,
          ...{
            pt: { xs: 8, md: 10 },
          },
          backgroundColor: 'black',
        }}
      >
        {children}
      </Stack>

      <Footer />
    </Box>
  );
}

MainLayout.propTypes = {
  children: PropTypes.node,
};
