import { createAsyncThunk } from '@reduxjs/toolkit';

import { get, put } from './http';
import { activeSnack } from '../store/common';

export const getAllReferrals = createAsyncThunk(
  'referral/list',
  async ({ page, search, limit, dispatch }) => {
    try {
      const response = await get(
        `member/refferals?page=${page && page}&search=${search && search}&limit=${limit || 5}`
      );
      // console.log(response);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
// enquiry

export const getAllEnquiry = createAsyncThunk(
  'enquiry/list',
  async ({ page, search, limit, dispatch, date, sort }) => {
    try {
      const response = await get(
        `enquiry/admin/all?page=${page || 'page'}&search=${search || ''}&limit=${
          limit || 25
        }&dateFrom=${date?.from || ' '}&dateTo=${date?.to || ''}&sort=${sort || ''}`
      );
      // console.log(response);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

// update
export const updateEnquiryStatus = createAsyncThunk(
  'enquiry/update',
  async ({ state, dispatch }) => {
    // console.log(state);
    try {
      const URL = `enquiry/admin/${state?.id}`;
      const response = await put(URL, state);

      if (response) {
        dispatch(
          getAllEnquiry({
            page: 1,
            search: '',
            limit: '',
            dispatch,
            date: '',
          })
        );
        dispatch(activeSnack({ type: 'success', message: 'Enquiry Status updated Successfully' }));
        return response;
      }

      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      // console.log(error);
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
