import * as React from 'react';
import PropTypes from 'prop-types';

import { Stack } from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
// eslint-disable-next-line import/no-extraneous-dependencies
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
// eslint-disable-next-line import/no-extraneous-dependencies
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
// eslint-disable-next-line import/no-extraneous-dependencies
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';

export default function BasicDateRangePicker({
  selectedRange,
  setSelectedRange,
  selectedRangeFormatted,
}) {
  // console.log('SingleInputDateRangeField =>', SingleInputDateRangeField);

  const handleDateRangeChange = (newRange) => {
    setSelectedRange(newRange);
  };

  return (
    <Stack className="calender-view">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['SingleInputDateRangeField']}>
          <DateRangePicker
            value={selectedRange}
            onChange={handleDateRangeChange}
            slots={{ field: SingleInputDateRangeField }}
            name="allowedRange"
          />
        </DemoContainer>
      </LocalizationProvider>
    </Stack>
  );
}
BasicDateRangePicker.propTypes = {
  selectedRange: PropTypes.string,
  setSelectedRange: PropTypes.string,
  selectedRangeFormatted: PropTypes.func,
};
