import { createAsyncThunk } from '@reduxjs/toolkit';

import { get, put, post } from './http';
import { activeSnack } from '../store/common';
import { getMemberProfileDetails } from './member';

export const getAllWithdrawalRequest = createAsyncThunk(
  'withdrawal-request/list',
  async ({ page, limit, sort, date, search, dispatch }) => {
    try {
      const response = await get(
        `/withdrawal-request/member/all?page=${page && page}&limit=${limit || 5}&sort=${
          sort || ''
        }&dateFrom=${date.from || ''}&dateTo=${date.to || ''}&search=${search || ''}`
      );
      // console.log(response);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const createWithdrawalRequest = createAsyncThunk(
  'withdrawal-request/create',
  async ({
    state,
    dispatch,
    handleNextStep,
    setOpenWithdrawalRequestSuccess,
    handleClose,
    setActiveStep,
  }) => {
    try {
      const URL = `/withdrawal-request/member/new`;
      const response = await post(URL, state);
      if (response) {
        if (handleNextStep) {
          handleNextStep();
        }
        if (handleClose) {
          handleClose();
        }
        if (setActiveStep) {
          setActiveStep(0);
        }
        dispatch(
          getMemberProfileDetails({
            dispatch,
          })
        );
        if (setOpenWithdrawalRequestSuccess) {
          setOpenWithdrawalRequestSuccess(true);
        }
        dispatch(
          activeSnack({ type: 'success', message: 'Withdrawal Request created successfully' })
        );
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const getAllWithdrawalRequestAdmin = createAsyncThunk(
  'withdrawal-request/list',
  async ({ page, limit, sort, date, dispatch, search }) => {
    try {
      const response = await get(
        `/withdrawal-request/admin/all?page=${page && page}&limit=${limit || 25}&sort=${
          sort || ''
        }&dateFrom=${date.from || ''}&dateTo=${date.to || ''}&search=${search || ''}`
      );
      // console.log(response);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateWithdrawalRequestAdmin = createAsyncThunk(
  'withdrawal-request/update',
  async ({ state, dispatch, handleClose }) => {
    try {
      const response = await put(`/withdrawal-request/admin/${state?.id}`, state);
      // console.log(response);
      if (response) {
        dispatch(
          getAllWithdrawalRequestAdmin({
            page: '',
            limit: '',
            sort: '',
            date: '',
            dispatch,
            search: '',
          })
        );
        dispatch(activeSnack({ type: 'success', message: 'Status updated successfully' }));
        handleClose();
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
