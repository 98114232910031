import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';

import { USDT } from 'src/hooks/use-USDT';

// ----------------------------------------------------------------------

export default function IncomeWidgetSummary({
  title,
  percent,
  total,
  chart,
  backgroundColor,
  sx,
  icon,
  text,
  variantValue = "h4",
  ...other
}) {
  return (
    <Card sx={{ display: 'flex',height:'100%', alignItems: 'center', backgroundColor, p: 3, ...sx }} {...other}>
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="subtitle2">{title}</Typography>
        {/* <Stack direction="row" alignItems="center" sx={{ mt: 2, mb: 1 }}>
          <Iconify
            width={45}
            icon={icon}
            sx={{
              mr: 1,
              color: 'info.main',
            }}
          />
          <TextMaxLine line={2}>
            <Typography component="div" variant="body2" color="#707070">
              {text}
            </Typography>
          </TextMaxLine>
        </Stack> */}

        <Typography variant={variantValue || 'h6'} sx={{ wordWrap: 'break-word', overflowWrap: 'anywhere' }}>
          {/* {fNumber(total)} */}
          {/* {`${fShortenNumber(total?.toFixed(2))} ${USDT}`} */}
          {`${total?.toFixed(2)} ${USDT}`}
        </Typography>
      </Box>
    </Card>
  );
}

IncomeWidgetSummary.propTypes = {
  chart: PropTypes.object,
  percent: PropTypes.number,
  sx: PropTypes.object,
  title: PropTypes.string,
  total: PropTypes.number,
  icon: PropTypes.any,
  text: PropTypes.string,
  backgroundColor: PropTypes.string,
  variantValue: PropTypes.string,
};
