import PropTypes from 'prop-types';

import { Grid } from '@mui/material';

import { useResponsive } from 'src/hooks/use-responsive';

import MemberCards from '../Small.Widgets';

export default function MiniWidgets({ memberDetails }) {
  const isMobile = useResponsive('down', 'md');

  return (
    <Grid container pt={isMobile && 1}>
      <Grid md={4} xs={6}>
        <MemberCards
          title={memberDetails?.member_id}
          club={memberDetails?.current_club}
          name="memberId"
        />
      </Grid>
      <Grid md={4} pr={2} px={isMobile ? 1 : 2} xs={6}>
        <MemberCards
          title={memberDetails?.is_club_member}
          club={memberDetails?.current_club}
          name="club"
        />
      </Grid>
      <Grid md={4} xs={12} pt={isMobile && 1}>
        <MemberCards
          title={memberDetails?.wallet_balance}
          club={memberDetails?.current_club}
          name="wallet"
        />
      </Grid>
    </Grid>
  );
}
MiniWidgets.propTypes = {
  memberDetails: PropTypes.object,
};
