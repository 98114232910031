import { createAsyncThunk } from '@reduxjs/toolkit';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const createMemberClub = createAsyncThunk(
  'member-club/create',
  async ({ state, dispatch, handleClose, handleClear }) => {
    try {
      const URL = `/member_club/admin/new`;
      const response = await post(URL, state);
      if (response) {
        handleClose();
        handleClear();
        dispatch(activeSnack({ type: 'success', message: 'Member Club created successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllMemberClubs = createAsyncThunk(
  'member-club/list',
  async ({ page, search, dispatch, limit }) => {
    try {
      const response = await get(
        `/member_club/admin/all?page=${page && page}&search=${search && search}&limit=${limit || 5}`
      );
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getMemberClubDetails = createAsyncThunk(
  'member-club/single',
  async ({ memberClubId, dispatch }) => {
    try {
      const response = await get(`/member_club/admin/${memberClubId}`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateMemberClubDetails = createAsyncThunk(
  'member-club/update',
  async ({ state, memberClubId, dispatch, handleClose, handleClear }) => {
    try {
      const URL = `/member_club/admin/${memberClubId}`;

      const response = await put(URL, state);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        dispatch(activeSnack({ type: 'success', message: 'Member Club updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteMemberClub = createAsyncThunk(
  'member-club/delete',
  async ({ memberClubId, dispatch, handleCloseDeleteDialog }) => {
    try {
      const response = await del(`/member_club/admin/${memberClubId}/`);
      if (response) {
        handleCloseDeleteDialog();
        dispatch(activeSnack({ type: 'success', message: 'Member Club deleted Successfully' }));
        return response?._id;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const createMemberClubWithdrawalRequest = createAsyncThunk(
  '/memberClub/withdrawal',
  async ({ state, dispatch, handleNextStep, setOpenWalletTransferSuccess, handleClose,setActiveStep }) => {
    try {
      // const URL = `/memberClub/withdrawal/${club}`;
      const URL = `/memberClub/withdrawal`;

      const response = await post(URL, state);
      if (response) {
        if (handleNextStep) {
          handleNextStep();
        }
        if (handleClose) {
          handleClose();
        }
        if (setActiveStep) {
          setActiveStep(0);
        }
        if (setOpenWalletTransferSuccess) {
          setOpenWalletTransferSuccess(true);
        }
        dispatch(
          getAllMemberClubsForMember({
            page: 1,
            search: '',
            limit: '',
            dispatch,
          })
        );
        dispatch(
          activeSnack({
            type: 'success',
            message: 'Transfer to personal wallet completed successfully',
          })
        );
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllMemberClubsForMember = createAsyncThunk(
  'member-club/member/list',
  async ({ page, search, limit, dispatch }) => {
    try {
      const response = await get(
        `memberClub/member/all?page=${page && page}&search=${search && search}&limit=${limit || 5}`
      );
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
