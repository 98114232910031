import PropTypes from 'prop-types';
import { enqueueSnackbar } from 'notistack';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { Paper, Stack, Button, CardMedia, Typography } from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import { fDateTime } from 'src/utils/format-time';

import copy from 'src/assets/other/copygreen.png';
import { frontEndUrl } from 'src/server/api/http';

// ----------------------------------------------------------------------

export default function JoinedOnDetails({ memberDetails }) {
  const handleCopy = () => {
    enqueueSnackbar('Link copied!', { variant: 'success' });
  };
  return (
    <Paper
      variant="outlined"
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent:'space-between',
        border: '1px dashed #ccee',
        py: 3.5,
        px: 2,
        borderRadius: 2,
      }}
    >
      <Stack flexDirection="" alignItems="start" justifyContent="start">
        <Typography sx={{ color: '#919EAB', fontSize: 12 }}> Joined on</Typography>
        <Typography sx={{ fontSize: 14, }}>
          {fDateTime(memberDetails?.createdAt)}
        </Typography>
      </Stack>
      {/* <Stack flexDirection="" alignItems="start" justifyContent="start" mt={2}>
        <Typography sx={{ color: '#919EAB', fontSize: 12 }}>Wallet address last changed</Typography>
        <Typography sx={{ fontSize: 14, color: '#212B36' }}>
          {fDateTime(memberDetails?.createdAt)}
        </Typography>
      </Stack> */}
      <Stack direction="row" alignItems="center" justifyContent="start" py={2}>
        {/* <Button sx={{ color: '#007867', backgroundColor: '#00A76F14', p: 2 }} size="small">
          {' '}
          <ContentCopyIcon fontSize="small" sx={{ fontSize: 16 }} />
          &nbsp; Copy Member`s Referral Link
        </Button> */}
        <CopyToClipboard
          // text={`${paths.auth.register}?ref='${MemberProfileDetails?.member_id}`}
          text={`${frontEndUrl}${paths.auth.register}?ref_id=${memberDetails?.member_id}`}
          onCopy={handleCopy}
        >
          <Button
            size="small"
            variant="contained"
            startIcon={<CardMedia component="img" src={copy} alt="copy" sx={{ width: '15px' }} />}
            sx={{
              color: '#007867',
              backgroundColor: '#00A76F14',
              p: 2,
              '&:hover': { backgroundColor: '#00A76F14' },
            }}
          >
            &nbsp; Copy Member`s Referral Link
          </Button>
        </CopyToClipboard>
      </Stack>
    </Paper>
  );
}
JoinedOnDetails.propTypes = {
  memberDetails: PropTypes.object,
};
