import { createSlice } from '@reduxjs/toolkit';

import {
  getAllIncentiveLevels,
  getAllMemberIncentives
} from '../api/incentive';

export const incentiveSlice = createSlice({
  name: 'incentive',
  initialState: {
    loading: false,
    error: {},
    incentiveLevels: {},
    memberIncentives: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    [getAllIncentiveLevels.pending]: (state) => {
      state.loading = true;
    },
    [getAllIncentiveLevels.fulfilled]: (state, action) => {
      state.loading = false;
      state.incentiveLevels = action.payload;
      state.error = {};
    },
    [getAllIncentiveLevels.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getAllMemberIncentives.pending]: (state) => {
      state.loading = true;
    },
    [getAllMemberIncentives.fulfilled]: (state, action) => {
      state.loading = false;
      state.memberIncentives = action.payload;
      state.error = {};
    },
    [getAllMemberIncentives.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError } = incentiveSlice.actions;

export default incentiveSlice.reducer;
