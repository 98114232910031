import React from 'react';
import PropTypes from 'prop-types';

import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Divider from '@mui/material/Divider';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { Box, Grid, Stack, TextField, Typography, Autocomplete, ListItemText } from '@mui/material';

import { useResponsive } from 'src/hooks/use-responsive';

import { fCurrency } from 'src/utils/format-number';
import { fDate, fTime } from 'src/utils/format-time';

import BasicDateRangePicker from 'src/pages/Common/Static/calender';

import Label from 'src/components/label';
import Scrollbar from 'src/components/scrollbar';
import TextMaxLine from 'src/components/text-max-line/text-max-line';
import { TableNoData, TableHeadCustom, TablePaginationCustom } from 'src/components/table';

// ----------------------------------------------------------------------

export default function ClubMemberTransactionsTable({
  title,
  date,
  setDate,
  selectedRangeFormatted,
  tableData,
  table,
  tableLabels,
  handleTextFieldChangeClub,
  handleAutocompleteChangeClub,
  optionClub,
  ...other
}) {
  // const [date, setDate] = React.useState([null, null]);

  const totalPage = tableData?.length;

  // console.log(selectedRangeFormatted);
  const isMobile = useResponsive('down', 'md');

  // console.log(tableData);
  const notFound = !tableData?.length || !tableData?.length;

  const identifier = 'clubIncome';
  return (
    <Card {...other}>
      <Typography color="#212B36" py={2} pt={4} px={3} sx={{ fontSize: 16, fontWeight: 700 }}>
        Club Income Transactions &nbsp;
      </Typography>
      <Stack px={1} py={1}>
        {' '}
        {/* <Stack direction="row" lg={5} md={5} padding="0px 0px 0px 0px" width="50%"> */}
        <Grid container>
          <Grid md={6} width="" xs={12}>
            <BasicDateRangePicker
              selectedRange={date}
              setSelectedRange={setDate}
              selectedRangeFormatted={selectedRangeFormatted}
            />
          </Grid>
          <Grid md={6} mt={1} xs={12} pl={isMobile && 0.5}>
            <Autocomplete
              id="country-select-demo"
              sx={{ width: '100%' }}
              options={optionClub}
              autoHighlight
              size="medium"
              // className="small-select"
              getOptionLabel={(option) => option.label}
              onChange={handleAutocompleteChangeClub}
              renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                  {option.label}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Club"
                  onChange={handleTextFieldChangeClub}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
        {/* <TextField
            name="date"
            label="Start Date"
            sx={{ width: '100%', mr: 2 }}
            size="medium"
            type="date"
            variant="filled"
            inputProps={{ maxLength: 4 }}
            InputLabelProps={{ shrink: true }}
            onChange={(e) =>
              setDate({
                ...date,
                start_date: e.target.value,
              })
            }
          />
          <TextField
            name="date"
            label="End Date"
            sx={{ width: '100%' }}
            size="medium"
            type="date"
            variant="filled"
            InputLabelProps={{ shrink: true }}
            onChange={(e) =>
              setDate({
                ...date,
                end_date: e.target.value,
              })
            }
          /> */}
      </Stack>
      {/* <RefMemberSearch onSearch={handleSearch} /> */}
      {/* </Stack> */}
      <TableContainer sx={{ overflow: 'unset' }}>
        <Scrollbar>
          <Table sx={{ minWidth: 680 }}>
            <TableHeadCustom headLabel={tableLabels} />

            <TableBody>
              {tableData?.map((row) => (
                <AppNewInvoiceRow key={row.id} row={row} />
              ))}
            </TableBody>
            <TableNoData notFound={notFound} />
          </Table>
        </Scrollbar>
      </TableContainer>
      <TablePaginationCustom
        count={totalPage || 0}
        page={table.page}
        rowsPerPage={table.rowsPerPage}
        onPageChange={table.onChangePage}
        onRowsPerPageChange={table.onChangeRowsPerPage}
        dense={table.dense}
        onChangeDense={table.onChangeDense}
        identifier={identifier}
      />

      <Divider sx={{ borderStyle: 'dashed' }} />
    </Card>
  );
}

ClubMemberTransactionsTable.propTypes = {
  subheader: PropTypes.string,
  tableData: PropTypes.array,
  tableLabels: PropTypes.array,
  title: PropTypes.string,
  table: PropTypes.string,
  date: PropTypes.string,
  setDate: PropTypes.func,
  selectedRangeFormatted: PropTypes.array,
  optionClub: PropTypes.array,
  handleTextFieldChangeClub: PropTypes.func,
  handleAutocompleteChangeClub: PropTypes.func,
};

// ----------------------------------------------------------------------

function AppNewInvoiceRow({ row }) {
  // console.log(row);
  return (
    <TableRow
      sx={{ backgroundColor: row?.transaction_type === 'CREDIT' ? '#00A76F14' : '#FF563014' }}
    >
      <TableCell>
        <ListItemText
          primary={fDate(row?.createdAt)}
          secondary={fTime(row?.createdAt)}
          primaryTypographyProps={{ typography: 'body2', noWrap: true }}
          secondaryTypographyProps={{
            mt: 0.5,
            component: 'span',
            typography: 'caption',
          }}
        />
      </TableCell>

      <TableCell>
        <TextMaxLine line={2}>
          <Typography variant="body2">{row?.description || '---'}</Typography>
        </TextMaxLine>
      </TableCell>

      <TableCell>{row?.amount ? fCurrency(row.amount) : '0'}</TableCell>

      <TableCell>
        {row?.member_club ? (
          <Label variant="outlined" color="default">
            {row?.member_club?.club?.title}
          </Label>
        ) : (
          '---'
        )}
      </TableCell>
    </TableRow>
  );
}

AppNewInvoiceRow.propTypes = {
  row: PropTypes.object,
};
