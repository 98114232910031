import PropTypes from 'prop-types';
import { enqueueSnackbar } from 'notistack';
import CopyToClipboard from 'react-copy-to-clipboard';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import { alpha } from '@mui/material/styles';
import PersonIcon from '@mui/icons-material/Person';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import MarkEmailUnreadRoundedIcon from '@mui/icons-material/MarkEmailUnreadRounded';
import { Grid, Button, Tooltip, CardMedia, IconButton, Typography } from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import { useGetRoles } from 'src/hooks/useHandleSessions';

import { fDateSimple } from 'src/utils/format-time';

import { bgGradient } from 'src/theme/css';
import copy from 'src/assets/other/copy2.png';
import { frontEndUrl } from 'src/server/api/http';

// ----------------------------------------------------------------------

export default function MemberCover({
  username,
  email,
  avatarUrl,
  phone,
  coverUrl,
  verified,
  paid,
  country_code,
  name,
  setOpenModalDialog,
  setOpenModalPasswordDialog,
  memberID,
  createdAt,
}) {
  // const theme = useTheme();
  // console.log(name);
  const role = useGetRoles()?.role;
  // console.log(role);
  const handleCopy = () => {
    enqueueSnackbar('Link copied!', { variant: 'success' });
  };
  return (
    <Box
      sx={{
        ...bgGradient({
          color: alpha('#0a0909cc', 0.8),
          imgUrl: coverUrl,
        }),
        // backgroundColor: '#FFE0A3',
        height: 1,
        color: 'common.white',
      }}
    >
      <IconButton
        onClick={() => setOpenModalDialog(true)}
        sx={{
          position: 'absolute',
          top: 16,
          right: 16,
          color: 'common.white',
        }}
      >
        <EditRoundedIcon />
      </IconButton>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        sx={{
          left: { md: 24, xs: 0 },
          bottom: { md: 24, xs: 0 },
          zIndex: { md: 10, xs: 0 },
          pt: { xs: 6, md: 0 },
          pb: { xs: 6, md: 0 },
          position: { md: 'absolute' },
        }}
      >
        <Avatar
          alt={username}
          src={avatarUrl}
          sx={{
            mx: { md: 'auto', xs: 2 },
            width: { xs: 64, md: 128 },
            height: { xs: 64, md: 128 },
            // border: `solid 2px ${theme.palette.common.white}`,
          }}
        >
          {username?.charAt(0).toUpperCase()}
        </Avatar>

        <Stack mt={4} px={2}>
          {role !== 'admin' && (
            <Grid display="flex" alignItems="center">
              <Typography variant="h4">{name}</Typography>
              &nbsp;
              {verified === true && (
                <Tooltip title="Verified">
                  {' '}
                  <VerifiedRoundedIcon color="success" fontSize="large" />
                </Tooltip>
              )}
              {verified === false && paid === true && (
                <Tooltip title="Account unverified">
                  {' '}
                  <WarningRoundedIcon color="warning" fontSize="large" />
                </Tooltip>
              )}
              {paid === false && (
                <Tooltip title="Account Unpaid">
                  {' '}
                  <WarningRoundedIcon color="error" fontSize="large" />
                </Tooltip>
              )}
            </Grid>
          )}
          {role === 'admin' && (
            <Grid display="flex" alignItems="center">
              <Typography variant="h4">{name}</Typography>
              &nbsp;
              {verified === true && (
                <Tooltip title="Verified">
                  {' '}
                  <VerifiedRoundedIcon color="success" fontSize="large" />
                </Tooltip>
              )}
              {verified === false && paid === true && (
                <Tooltip title="Account unverified">
                  {' '}
                  <WarningRoundedIcon color="warning" fontSize="large" />
                </Tooltip>
              )}
              {paid === false && (
                <Tooltip title="Account Unpaid">
                  {' '}
                  <WarningRoundedIcon color="error" fontSize="large" />
                </Tooltip>
              )}
            </Grid>
          )}
          <Grid display="flex" alignItems="center" pt={1}>
            <PersonIcon sx={{ fontSize: 12 }} fontSize="small" /> &nbsp;
            <Typography variant="body2">{username}</Typography>
          </Grid>
          <Grid display="flex" alignItems="center" pt={1}>
            <MarkEmailUnreadRoundedIcon sx={{ fontSize: 12 }} fontSize="small" /> &nbsp;
            <Typography variant="body2">{email}</Typography>
          </Grid>
          {phone && (
            <Grid display="flex" alignItems="center">
              <LocalPhoneRoundedIcon sx={{ fontSize: 12 }} fontSize="small" /> &nbsp;
              <Typography variant="body2">
                {' '}
                {country_code}&nbsp;{phone}
              </Typography>
            </Grid>
          )}
          <Stack spacing={1} flexDirection='row'>
            <Typography sx={{ typography: 'caption', opacity: 0.48 }}>Joined on</Typography>
            <Typography sx={{ typography: 'caption' }}> {fDateSimple(createdAt)}</Typography>
          </Stack>
        </Stack>
      </Stack>
      {role !== 'admin' ? (
        <Typography
          variant="caption"
          sx={{
            cursor: 'pointer',
            position: 'absolute',
            bottom: 16,
            right: 16,
            '&:hover': {
              color: 'primary.main',
            },
          }}
          onClick={() => setOpenModalPasswordDialog(true)}
        >
          Update Password
        </Typography>
      ) : (
        <CopyToClipboard
          // text={`${paths.auth.register}?ref='${MemberProfileDetails?.member_id}`}
          text={`${frontEndUrl}${paths.auth.register}?ref_id=${memberID}`}
          onCopy={handleCopy}
        >
          <Button
            size="small"
            variant="contained"
            startIcon={<CardMedia component="img" src={copy} alt="copy" sx={{ width: '15px' }} />}
            sx={{
              position: 'absolute',
              bottom: 16,
              right: 16,
              color: 'white',
              backgroundColor: '#00A76F14',
              p: 2,
              '&:hover': { backgroundColor: '#00A76F14' },
            }}
          >
            Copy Referral Link{' '}
          </Button>
        </CopyToClipboard>
      )}
    </Box>
  );
}

MemberCover.propTypes = {
  avatarUrl: PropTypes.string,
  coverUrl: PropTypes.string,
  username: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,
  verified: PropTypes.bool,
  paid: PropTypes.bool,
  country_code: PropTypes.bool,
  name: PropTypes.string,
  setOpenModalDialog: PropTypes.func,
  setOpenModalPasswordDialog: PropTypes.func,
  memberID: PropTypes.string,
  createdAt: PropTypes.string,
};
