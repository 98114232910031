import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

export const MetaHelmet = ({ title, miniDescription, mainDescription }) => (
  <Helmet>
    <title>{`${title} | ${miniDescription||''}`}</title>
    <meta name="description" content={mainDescription} />
  </Helmet>
);

MetaHelmet.propTypes = {
  title: PropTypes.string.isRequired,
  miniDescription: PropTypes.string.isRequired,
  mainDescription: PropTypes.string,
};
