import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import { Stack } from '@mui/system';
import RecentActorsRoundedIcon from '@mui/icons-material/RecentActorsRounded';
import { Chip, Button, Tooltip, Container, IconButton, Typography } from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import { MetaHelmet } from 'src/layouts/Helmet/Helmet';
import { getAllChainNodeData } from 'src/server/api/dashboard';

import { useSettingsContext } from 'src/components/settings';

import ChainLoader from './loading';
import { useKeyPress } from './useKeyPress';

function ChainNodesView() {
  const dispatch = useDispatch();
  const settings = useSettingsContext();

  const { chain, loading } = useSelector((state) => ({
    loading: state.dashboard.loading,
    chain: state.dashboard.chain,
  }));

  const [page, setPage] = useState(1);
  const [limit] = useState(50);

  const totalChains = chain?.total;
  const totalPages = Math.ceil(totalChains / limit);

  const [chainDataBlock, setChainDataBlock] = useState([]);
  const [dataFetchLoad, setDataFetchLoad] = useState(false);

  // console.log(chainDataBlock);

  useEffect(() => {
    const state = {
      page,
      search: '',
      dispatch,
      limit,
    };
    dispatch(getAllChainNodeData(state));
  }, [dispatch, limit, page]);

  // const finalDemo = generateDemoData(100);

  useEffect(() => {
    if (chain?.page === 1) {
      const initialData = totalChains >= 1 ? chain.chainNodes || [] : [];
      // console.log(initialData);
      setChainDataBlock(initialData);
    } else {
      setChainDataBlock((prevData) => [...prevData, ...(chain.chainNodes || [])]);
    }
  }, [chain.chainNodes, totalChains, page, chain?.page]);

  useEffect(() => {
    const removeDuplicates = (array) => {
      const seen = {};
      return array.filter((item) => {
        const id = item._id || item.node_number;
        if (seen[id]) {
          return false;
        }
        seen[id] = true;
        return true;
      });
    };

    setChainDataBlock(removeDuplicates(chainDataBlock));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const CalculateTheHeight = (index) => {
    if (index === 0) {
      return '0px';
    }
    if (index === 1) {
      return '120px';
    }
    return `${120 * index - 5}px`;
  };

  // Map chainDataBlock to groupedData in chunks of 6 using map function
  const groupedData = chainDataBlock.reduce((acc, item, index) => {
    const chunkIndex = Math.floor(index / 6);
    if (!acc[chunkIndex]) {
      acc[chunkIndex] = [];
    }
    acc[chunkIndex].push(item);
    return acc;
  }, []);

  // Map groupedData to finalData format
  const modifiedFinalData = groupedData.map((group, index) => ({
    nodeType: index % 2 === 0 ? 'straight' : 'invert',
    top: CalculateTheHeight(index),
    data: group,
  }));

  const totalLength = modifiedFinalData?.length;

  // console.log(modifiedFinalData);
  // console.log(finalDemo);

  const handleLoadNextChains = () => {
    if (page < totalPages) {
      setPage(page + 1);
      setDataFetchLoad(true);
    } else {
      // console.log('No more pages to load');
    }
  };

  useEffect(() => {
    if (dataFetchLoad === true) {
      setTimeout(() => {
        setDataFetchLoad(false);
      }, 2000);
    }
  }, [dataFetchLoad]);

  // const currentLoadedDataLength = chainDataBlock?.length;

  const [myCombination, setMyCombination] = useState('');
  const [authorized, setAuthorized] = useState(false);

  const onKeyPress = (event) => {
    setMyCombination(myCombination + event.key);
  };

  useKeyPress(['Control', 'Shift', 'o', 's', 'p', 'e', 'r', 'b'], onKeyPress);

  useEffect(() => {
    if (localStorage.getItem('key')) {
      setAuthorized(true);
    }

    if (myCombination === 'ControlShiftosperb') {
      setMyCombination('');
      setAuthorized(true);
      localStorage.setItem('key', true);
    }
  }, [myCombination]);

  return (
    <>
      <MetaHelmet title="Winbuks" miniDescription="Winbuks Chain" />
      {authorized ? (
        <Container
          maxWidth={settings.themeStretch ? false : 'xl'}
          sx={{
            border: '1px solid #f7f8fa',
            padding: 2,
            borderRadius: 5,
            background: '#ffff',
            boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
            height: `${totalLength * 140}px`,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          {dataFetchLoad || loading ? (
            <Box>
              <ChainLoader />
            </Box>
          ) : (
            <>
              <Box>
                <Typography variant="subtitle2" mx={2}>
                  Winbuks Chain Visualization
                </Typography>
                <Container
                  maxWidth="xl"
                  sx={{
                    mb: 3,
                    minHeight: '100vh',
                    p: 2,
                    py: 3,
                    borderRadius: 2,
                    width: '1100px',
                  }}
                >
                  <Box sx={{ width: '100%', position: 'relative' }} className="chain-node-head">
                    {modifiedFinalData?.map((items, index) => (
                      <Box
                        key={index}
                        sx={{
                          display: 'flex',
                          top: items?.top || 0,
                          position: 'absolute',
                          width: '100%',
                        }}
                        className={`line-${items?.nodeType}`}
                      >
                        {items?.data?.map((c_node, key) => (
                          <Stack
                            className={`${items?.data?.length === key + 1 ? 'curved-line-head' : ''} `}
                            direction="row"
                            key={key}
                            alignItems="center"
                            justifyContent="start"
                            sx={{ position: 'relative' }}
                          >
                            <Chip
                              color="default"
                              label={c_node?.node_number}
                              sx={{
                                // minWidth: '15px',
                                // maxWidth: '50px',
                                // width: 'auto',
                                // width: 'fit-content',
                                // borderRadius: '50%',
                                width: '48px',
                                borderRadius: '4px',
                                padding: '0px 2px',
                                height: '15px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: c_node?.is_active === true ? '#22c55d' : '#a6b2bd',
                                color: '#ffff',
                                cursor: 'pointer',
                              }}
                              className="c-node-chip"
                            />
                            <Box
                              sx={{
                                position: 'absolute',
                                background: c_node?.is_active === true ? '#ebf9f2' : '#f0f5f8',
                                height: 50,
                                width: 120,
                                borderRadius: 1,
                                px: 1.2,
                                boxShadow:
                                  c_node?.is_active === true
                                    ? 'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,rgba(0, 0, 0, 0.05) 0px 4px 6px -2px'
                                    : '',
                                zIndex: 99,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                transition: 'transform 0.5s ease',
                              }}
                              className={`c-node-data ${c_node?.is_active === true ? 'hover-zoom' : ''}`}
                            >
                              <Stack sx={{ height: '100%', justifyContent: 'center' }}>
                                <Typography
                                  variant="caption"
                                  pb={0}
                                  mb={0}
                                  sx={{ fontWeight: 500 }}
                                >
                                  {' '}
                                  <Tooltip title="Initial Wallet Balance">IWB</Tooltip>&nbsp;:{' '}
                                  {c_node?.initial_wallet_balance}
                                </Typography>

                                <Typography variant="caption">
                                  <Tooltip title="Received Amount"> CE </Tooltip>&nbsp;:{' '}
                                  {c_node?.received_amount}
                                </Typography>
                              </Stack>
                              <Tooltip
                                title={
                                  c_node?.is_active === true
                                    ? `Member details of chain ${c_node?.node_number}`
                                    : ''
                                }
                              >
                                <Link to={paths.dashboard.member_view(c_node?.member)}>
                                  <IconButton
                                    size="small"
                                    sx={{ display: 'flex', flexDirection: 'column' }}
                                  >
                                    <RecentActorsRoundedIcon
                                      fontSize="small"
                                      color={c_node?.is_active === false ? 'disabled' : ''}
                                      sx={{
                                        color: c_node?.is_active === true ? '#245e60' : '',
                                      }}
                                    />
                                  </IconButton>
                                </Link>
                              </Tooltip>
                            </Box>

                            <Box
                              sx={{
                                borderColor: c_node?.is_active === true ? '#22c55d' : '#cdd2d6',
                                display: 'block',
                                borderTopStyle: 'solid',
                                borderTopWidth: '1px',
                              }}
                              className={`${items?.data?.length === key + 1 ? 'chain-line-ex' : 'chain-line'} `}
                            />
                          </Stack>
                        ))}
                      </Box>
                    ))}
                  </Box>
                </Container>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  color="inherit"
                  sx={{
                    backgroundColor: '#f8f7f4',
                    color: '#000',
                    borderRadius: '20px',
                    '&:hover': {
                      color: '#000',
                      backgroundColor: '#ebf9f2',
                    },
                  }}
                  disabled={page >= totalPages}
                  onClick={handleLoadNextChains}
                >
                  <Typography variant="caption">Load more chains</Typography>
                </Button>
              </Box>
            </>
          )}
        </Container>
      ) : (
        <Box
          sx={{ height: '500px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          Enter your security code
        </Box>
      )}
    </>
  );
}

export default ChainNodesView;
